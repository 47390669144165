(function () {
  Drupal.behaviors.videoBlockV3 = {
    isMobile: null,

    attach: function (context) {
      const videoModules = context.querySelectorAll('.js-interactive-media-video');

      this.isMobile = this.checkIsMobile();

      videoModules.forEach((videoModule) => {
        this.initializeModule(videoModule);
      });

      if (this.hasDefinedSources(videoModules)) {
        window.addEventListener('resize', () => {
          const newIsMobile = this.checkIsMobile();

          if (this.isMobile !== newIsMobile) {
            this.isMobile = newIsMobile;
            videoModules.forEach((videoModule) => {
              this.updateModule(videoModule);
            });
          }
        });
      }
    },

    initializeModule: function (videoModule) {
      this.getDom(videoModule);
      this.setAttr(videoModule);
      this.addClickListeners(videoModule);
      this.addVideoEndListener(videoModule);
    },

    updateModule: function (videoModule) {
      const videoDt = videoModule.dataset.videoDt;
      const videoMob = videoModule.dataset.videoMob;

      this.getDom(videoModule);

      if ((this.isMobile && videoMob) || (!this.isMobile && videoDt)) {
        videoModule.nodes.heroContainer.classList.remove('video-active');
        this.setAttr(videoModule);
      }
    },

    hasDefinedSources: function (modules) {
      return Array.from(modules).some((mod) => mod.dataset.videoDt && mod.dataset.videoMob);
    },

    getDom: function (videoModule) {
      const heroContainer = videoModule.closest('.js-hero__media-wrapper');

      videoModule.nodes = {
        heroContainer: heroContainer,
        videoLandingImageContainer: heroContainer.querySelector('.js-video-block-v3__landing'),
        videoContainer: heroContainer.querySelector('.js-interactive-media-video'),
        video: videoModule.querySelector('.js-video-v3'),
        playButton: heroContainer.querySelector('.js-video-block-v3__control'),
        soundButton: videoModule.querySelector('.js-video-block-v3__sound'),
        playIcon: videoModule.querySelector('.js-icon--video_play_v2'),
        pauseIcon: videoModule.querySelector('.js-icon--video_pause_v2'),
        soundMute: videoModule.querySelector('.js-icon--sound_mute_v2'),
        soundUnmute: videoModule.querySelector('.js-icon--sound_unmute_v2')
      };
    },

    addClickListeners: function (videoModule) {
      const nodes = videoModule.nodes;

      nodes.heroContainer.addEventListener('click', (evt) => {
        const target = evt.target;

        if (target.closest('.js-video-block-v3__sound')) {
          evt.stopPropagation();
          this.handleSoundButton(videoModule);
        } else if (target.closest('.js-video-block-v3__control') ||
          target.closest('.js-video-block-v3__media, .js-video-block-v3__video')) {
          evt.stopPropagation();
          this.handlePlayAction(videoModule);
        }
      });
    },

    addVideoEndListener: function (videoModule) {
      const video = videoModule.nodes.video;

      video.addEventListener('ended', () => {
        videoModule.nodes.heroContainer.classList.remove('video-active');
      });
    },

    handlePlayAction: function (videoModule) {
      const video = videoModule.nodes.video;

      this.togglePlayPause(video, videoModule);
    },

    togglePlayPause: function (video, videoModule) {
      if (video.paused) {
        this.playVideo(videoModule);
      } else {
        this.pauseVideo(videoModule);
      }
    },

    playVideo: function (videoModule) {
      const nodes = videoModule.nodes;
      const video = nodes.video;

      video.play();
      nodes.playIcon.classList.add('hide');
      nodes.pauseIcon.classList.remove('hide');
      nodes.heroContainer.classList.add('video-active');
      nodes.videoContainer.classList.add('visible');

      if (video.muted) {
        video.muted = false;
        nodes.soundMute.classList.add('hide');
        nodes.soundUnmute.classList.remove('hide');
      }
    },

    pauseVideo: function (videoModule) {
      const nodes = videoModule.nodes;
      const video = nodes.video;

      video.pause();
      nodes.playIcon.classList.remove('hide');
      nodes.pauseIcon.classList.add('hide');
    },

    handleSoundButton: function (videoModule) {
      const nodes = videoModule.nodes;
      const video = nodes.video;

      video.muted = !video.muted;
      nodes.soundMute.classList.toggle('hide', !video.muted);
      nodes.soundUnmute.classList.toggle('hide', video.muted);
    },

    checkIsMobile: function () {
      const bp = Unison.fetch.now();
      const bps = Unison.fetch.all();

      return parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
    },

    setAttr: function (videoModule) {
      const nodes = videoModule.nodes;
      const videoDt = videoModule.dataset.videoDt;
      const videoMob = videoModule.dataset.videoMob;
      const autoplay = videoModule.dataset.autoplay === 'true';
      const videoItem = nodes.video;

      if (autoplay) {
        nodes.heroContainer.classList.add('video-active');
        nodes.videoContainer.classList.add('visible');
        nodes.playIcon.classList.add('hide');
        nodes.pauseIcon.classList.remove('hide');
        videoItem.muted = true;
        nodes.soundUnmute.classList.add('hide');
        nodes.soundMute.classList.remove('hide');
      }

      this.setVideoSource(videoItem, videoDt, videoMob);
    },

    setVideoSource: function (videoItem, videoDt, videoMob) {
      if (this.isMobile && videoMob) {
        videoItem.setAttribute('src', videoMob);
      } else if (videoDt) {
        videoItem.setAttribute('src', videoDt);
      }
    }
  };
})();
